import * as React from "react";
import { graphql } from "gatsby";
import Moment from "moment";

import { renderRichText } from "gatsby-source-contentful/rich-text";

import Layout from "../components/layout";
import Seo from "../components/seo";
import options from "../components/richtext";

const IndexPage = ({ data }) => (
    <div>
    <Seo title="News" />
    {data.allContentfulNews.nodes.map(article => (
      <article>
        <h2>{article.title}</h2>
        <p>
          <small>{Moment(article.createdAt).format("ddd DD MMM yy")}</small>
        </p>
       {article.image !== null && (<p><img alt="" class="img-fluid" src={article.image.fixed.src} /></p>)}
       
      
                            <div
            className="blog-post-content"
            dangerouslySetInnerHTML={{
              __html:
                article.content
                  .childrenMarkdownRemark[0].html
            }}
          />
      </article>
    ))}
    </div>
);

export const query = graphql`
  {
    allContentfulNews(sort: { fields: [createdAt], order: DESC }) {
      nodes {
        title
        createdAt
      content {
        childrenMarkdownRemark {
          html
        }
      }
        image {
          id
          fixed(width: 800) {
            src
          }
        }
      }
    }
  }
`;

export default IndexPage;
